/**
 * @flow
 */

import { Field, Form, Formik } from "formik"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { useMutation } from "@apollo/react-hooks"

import { PRACTICE_QUERY } from "../queries"
import { Button, TextField } from "./elements"

export type Values = {
  quantity: number,
}

type Props = {
  activeSubscriptionQuantity?: number,
  currentUserCount?: number,
  planCost?: number,
  onChange?: (data: Values) => void,
  onError?: (err: Error) => void,
  invertBackground: boolean,
}

const InlineFormContainer = styled.div`
  h3 {
    font-size: 1.15rem;
    margin-bottom: 1rem;
  }
  form {
    display: grid;
    grid-template: auto auto / 1fr;
    .field-container {
      input {
        background-color: ${props =>
          props.invertBackground ? `var(--white)` : `var(--alabaster)`};
      }
    }
    .button-container {
      display: flex;
      align-items: center;
    }
  }
  @media (min-width: 768px) {
    form {
      grid-template: auto / 1fr 1fr;
      grid-gap: var(--gutter-m);
    }
  }
`

const PlanForm = ({
  activeSubscriptionQuantity = 0,
  currentUserCount = 0,
  planCost = 0,
  onChange = (data: Values) => {
    // eslint-disable-next-line no-console
    console.log(data)
  },
  onError = (err: Error) => {
    console.error(err)
  },
  invertBackground,
}: Props) => {
  const [updatePlan] = useMutation(UPDATE_PLAN_MUTATION, {
    refetchQueries: [
      {
        query: PRACTICE_QUERY,
      },
    ],
  })

  const initialValues = {
    quantity: activeSubscriptionQuantity || 1,
  }

  const validate = values => {
    const errors = {}
    const quantity = Number(values.quantity)
    if (isNaN(quantity)) {
      errors.quantity = "Please enter a valid number."
    } else {
      if (quantity < currentUserCount) {
        errors.quantity = "Remove users before downgrading your plan."
      }
      if (quantity <= 0) {
        errors.quantity = "You need at least 1 user."
      }
    }
    return errors
  }

  const isInitialValid = validate(initialValues)

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const variables = {
        quantity: values.quantity,
      }
      await updatePlan({
        variables,
      })
      setSubmitting(false)
      onChange(variables)
    } catch (err) {
      setSubmitting(false)
      onError(err)
    }
  }

  const buttonTitle = quantity => {
    let title = "Select"
    if (!isNaN(quantity)) {
      let action = "Keep Plan"
      if (activeSubscriptionQuantity === 0) {
        action = "Start Plan"
      } else if (quantity > activeSubscriptionQuantity) {
        action = "Upgrade Plan"
      } else if (quantity < activeSubscriptionQuantity) {
        action = "Downgrade Plan"
      }
      title = `${action} @R${(planCost / 100) * quantity} p/m`
    }
    return title
  }

  // eslint-disable-next-line react/prop-types
  const renderForm = ({ handleSubmit, values, isValid, isSubmitting }) => {
    // eslint-disable-next-line react/prop-types
    const quantity = Number(values.quantity)

    const buttonDisabled =
      !isValid ||
      isSubmitting ||
      (activeSubscriptionQuantity > 0 &&
        quantity === activeSubscriptionQuantity)

    return (
      <Form>
        <div className="field-container">
          <Field
            name="quantity"
            id="quantity"
            component={TextField}
            label="Number of Users"
            type="number"
          />
        </div>
        <div className="button-container">
          <Button
            type="submit"
            disabled={buttonDisabled}
            loading={isSubmitting}
            onClick={handleSubmit}
          >
            {buttonTitle(quantity)}
          </Button>
        </div>
      </Form>
    )
  }

  return (
    <InlineFormContainer invertBackground={invertBackground}>
      <h3 className="font-semi-black">
        {activeSubscriptionQuantity
          ? "Change subscription"
          : "Activate subscription"}
      </h3>
      <Formik
        initialValues={initialValues}
        validate={validate}
        isInitialValid={isInitialValid}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {renderForm}
      </Formik>
    </InlineFormContainer>
  )
}

PlanForm.propTypes = {
  activeSubscriptionQuantity: PropTypes.number,
  currentUserCount: PropTypes.number,
  planCost: PropTypes.number,
}

export default PlanForm

const UPDATE_PLAN_MUTATION = gql`
  mutation UpdatePlan($quantity: Int) {
    subscribePracticeToPlan(quantity: $quantity) {
      ... on Error {
        message
      }
      ... on SubscribePracticeToPlanSuccess {
        practice {
          id
        }
      }
    }
  }
`
