/**
 * @flow
 */

import { Field, Form, Formik } from "formik"
import React, { useState } from "react"
import * as yup from "yup"

import { validatePhoneNumber } from "../../utils/phonenumber"
import {
  Button,
  EmailField,
  PasswordField,
  TextField,
  CheckBoxField,
} from "../elements"

const validationSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Required"),
  mobileNumber: yup
    .string()
    .required("Required")
    .test("phone-number", "Invalid phone number", value =>
      validatePhoneNumber(value)
    ),
  password: yup.string().required("Required"),
})

type Values = {
  firstName: string,
  lastName: string,
  email: string,
  mobileNumber: string,
  password: string,
}

type Actions = {
  setSubmitting: Function,
}

type Props = {
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
  initialValues?: Values,
}

const SignUpForm = ({
  onSubmit,
  initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
  },
}: Props) => {
  const [terms, setTerms] = useState(false)
  const toggleTerms = () => {
    setTerms(!terms)
  }
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Field
            autoComplete="given-name"
            autoFocus
            component={TextField}
            id="firstName"
            label="First Name"
            name="firstName"
            required
          />
          <Field
            autoComplete="family-name"
            component={TextField}
            id="lastName"
            label="Last Name"
            name="lastName"
            required
          />
          <Field
            autoComplete="email"
            component={EmailField}
            id="email"
            label="Email Address"
            name="email"
            placeholder="someone@example.com"
            required
          />
          <Field
            autoComplete="tel"
            component={TextField}
            id="mobileNumber"
            label="Phone Number"
            name="mobileNumber"
            required
          />
          <Field
            autoComplete="current-password"
            component={PasswordField}
            id="password"
            label="Password"
            name="password"
            required
          />
          <CheckBoxField onSelect={toggleTerms} active={terms} />
          <Button
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting || !terms}
          >
            Create Account
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default SignUpForm
