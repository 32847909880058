/**
 * @flow
 */

import React from "react"
import styled from "styled-components"

import PlanForm from "./PlanForm"

type Props = {
  practice: {
    billing: {
      activeSubscriptionQuantity: number,
      currentUserCount: number,
      currentInviteCount: number,
      planCost: number,
    },
  },
}

const Container = styled.div`
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`

const SubscriptionView = ({ practice }: Props) => {
  const { billing = {} } = practice
  const {
    activeSubscriptionQuantity = 0,
    currentUserCount = 0,
    currentInviteCount = 0,
    planCost = 0,
  } = billing
  const totalNumberOfUsers = currentUserCount + currentInviteCount
  const numberOfUsersRemaining = activeSubscriptionQuantity - totalNumberOfUsers

  let notice = `Your current plan allows for ${activeSubscriptionQuantity} active user(s).`
  if (numberOfUsersRemaining > 0) {
    notice += ` You can add another ${numberOfUsersRemaining} user(s).`
  } else {
    notice += ` To add more users upgrade your plan.`
  }

  return (
    <Container>
      <h2>Plan</h2>
      <p>
        {!activeSubscriptionQuantity && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.31 7.526c-.099-.807.528-1.526 1.348-1.526.771 0 1.377.676 1.28 1.451l-.757 6.053c-.035.283-.276.496-.561.496s-.526-.213-.562-.496l-.748-5.978zm1.31 10.724c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
          </svg>
        )}
        {notice}
      </p>
      <br />
      <PlanForm
        invertBackground={false}
        activeSubscriptionQuantity={activeSubscriptionQuantity}
        currentUserCount={totalNumberOfUsers}
        planCost={planCost}
      />
    </Container>
  )
}

export default SubscriptionView
