import Amplify from "aws-amplify"

const configureAmplify = () => {
  Amplify.Logger.LOG_LEVEL = process.env.AMPLIFY_LOGGER_LOG_LEVEL

  Amplify.configure({
    Auth: {
      identityPoolId: process.env.AMPLIFY_AUTH_IDENTITY_POOL_ID,
      region: process.env.AMPLIFY_AUTH_REGION,
      userPoolId: process.env.AMPLIFY_AUTH_USER_POOL_ID,
      userPoolWebClientId: process.env.AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID,
    },
    Analytics: {
      AWSPinpoint: {
        appId: process.env.ANALYTICS_PINPOINT_APP_ID,
        region: process.env.AMPLIFY_AUTH_REGION,
        mandatorySignIn: false,
      },
    },
  })
}

export default configureAmplify
