/**
 * @flow
 */
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

type Props = {
  active: boolean,
  onSelect: Function,
}

const Container = styled.div`
  display: flex;
  margin-bottom: 2rem;
  p {
    font-size: 0.9rem;
    a {
      color: var(--havelock-blue);
      &:hover {
        text-decoration: underline;
      }
    }
  }
`

const Box = styled.div`
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid var(--havelock-blue);
  padding: 2px;
  position: relative;
  margin-right: 1rem;
  &:after {
    content: "";
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: ${props =>
      props.active ? `rgba(80, 125, 220, 1)` : `rgba(80, 125, 220, 0)`};
    transition: 250ms ease-out;
    border-radius: 50%;
  }
  &:hover {
    cursor: pointer;
  }
`

const CheckBoxField = ({ active, onSelect }: Props) => {
  return (
    <Container>
      <Box active={active} onClick={onSelect} />
      <p>
        By signing up with Submitt you agree to our{" "}
        <Link to="/terms">terms and conditions</Link>
      </p>
    </Container>
  )
}

CheckBoxField.defaultProps = {
  active: false,
}

export default CheckBoxField
