import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import { Link as ScrollLink } from "react-scroll"

import logo from "../images/icons/logo.png"
import app_store from "../images/icons/apple-logo-icon.svg"
import play_store from "../images/icons/google-logo-icon.svg"

const Button = styled(Link)`
  background: rgb(255, 179, 161, 1);
  background: linear-gradient(
    90deg,
    rgba(255, 179, 161, 1) 35%,
    rgba(255, 141, 157, 1) 100%
  );
  color: var(--white) !important;
  padding: 0.75rem 2rem !important;
  border: none;
  border-radius: 30px;
  display: inline-block;
  font-size: 0.9rem;
  transition: all 250ms ease-out;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:after {
    content: none !important;
  }
`

const Button2 = styled(Button)`
  @media (min-width: 768px) {
    margin-left: -20px;
  }
  background: rgb(80, 125, 220, 1);
  background: linear-gradient(
    90deg,
    rgb(80, 125, 220) 35%,
    rgb(97, 160, 255) 100%
  );
`

const Container = styled.header`
  background-color: var(--alabaster);
  position: absolute;
  z-index: 9;
  width: 100%;
  padding: var(--gutter-s) 0 0;
  display: grid;
  grid-template: auto auto / 1fr 1fr;
  @media (min-width: 768px) {
    background-color: transparent;
    grid-template: auto / 200px 1fr;
    padding: var(--gutter-m) 0;
  }
`

const Logo = styled(Link)`
  padding: 0 0 var(--gutter-s) var(--gutter-s);
  display: flex;
  align-items: center;
  img {
    width: auto;
    max-height: 50px;
  }
  @media (min-width: 768px) {
    padding: 0 0 0 var(--gutter-l);
  }
`

const Icon = styled.div`
  justify-self: end;
  display: flex;
  align-items: center;
  padding: 0 var(--gutter-s) var(--gutter-s) 0;
  svg {
    path {
      fill: var(--manatee);
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`

const Menu = styled.ul`
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 0;
  height: 0;
  overflow: hidden;
  transition: 250ms ease-out;
  box-shadow: 0px 4px 6px -6px var(--black);
  li {
    list-style: none;
    min-height: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    a {
      font-family: "galano_grotesqueregular";
      font-weight: 400;
      text-align: center;
      width: 100%;
      color: var(--manatee);
      font-size: 0.9rem;
      padding: 0.5rem 0;
      display: inline-block;
      &.store-link {
        img {
          width: 15px;
          height: auto;
        }
      }
    }
  }
  &.open {
    min-height: calc(50px * 6);
  }
  @media (min-width: 768px) {
    justify-self: end;
    flex-direction: row;
    height: auto;
    min-height: auto;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    box-shadow: 0px 0px 0px 0px var(--black);
    padding: 0 var(--gutter-l) 0 0;
    li {
      min-height: auto;
      padding: 0 1rem;
      width: auto;
      a {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          width: 0%;
          height: 1px;
          background-color: var(--manatee);
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: 250ms ease-out;
        }
        &:hover {
          cursor: pointer;
          &:after {
            width: 100%;
          }
          &.store-link {
            &:after {
              width: 0;
            }
          }
        }
      }
      &:last-child {
        padding: 0 0;
      }
    }
  }
`

class Navbar extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
    this.frameId = null
  }
  componentWillUpdate() {}
  componentWillReceiveProps() {}
  componentDidMount() {
    window.addEventListener("resize", this.onResize, false)
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize, false)
    window.cancelAnimationFrame(this.frameId)
  }
  toggleMenu = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  breakpointCollapse = () => {
    // eslint-disable-next-line no-undef
    if (document.body.getBoundingClientRect().width < 768) {
      this.menu.classList.remove("open")
    }
  }
  onResize = () => {
    this.frameId = window.requestAnimationFrame(this.breakpointCollapse)
  }
  render() {
    let { isOpen } = this.state
    return (
      <Container>
        <Logo to="/">
          <img src={logo} alt="Submitt App" />
        </Logo>
        <Icon onClick={this.toggleMenu}>
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z" />
          </svg>
        </Icon>
        <Menu
          className={isOpen ? "open" : null}
          ref={ref => {
            this.menu = ref
          }}
        >
          {this.props.metaNavbar ? (
            <li>
              <Link to="/">Home</Link>
            </li>
          ) : (
            <>
              <li>
                <ScrollLink
                  to="how-it-works"
                  offset={-40}
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  How it works
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="pricing"
                  offset={-40}
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Pricing
                </ScrollLink>
              </li>
              <li>
                <ScrollLink
                  to="faq"
                  offset={-40}
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  FAQ
                </ScrollLink>
              </li>
              <li>
                <Button to="/sign-in">Sign In</Button>
              </li>
              <li>
                <Button2 to="/sign-up">Sign Up</Button2>
              </li>
            </>
          )}
          {!this.props.isDashboard && (
            <>
              <li>
                <a
                  href="https://apps.apple.com/za/app/submitt/id1457848422"
                  className="store-link"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={app_store} alt="Submitt App Store download" />
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=me.submitt"
                  className="store-link"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <img src={play_store} alt="Submitt Play Store download" />
                </a>
              </li>
            </>
          )}
        </Menu>
      </Container>
    )
  }
}

Navbar.propTypes = {
  metaNavbar: PropTypes.bool,
  isDashboard: PropTypes.bool,
}

export default Navbar
