// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/codebuild/output/src2449793091/src/bitbucket.org/teamgeek/submitt-web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("/codebuild/output/src2449793091/src/bitbucket.org/teamgeek/submitt-web/src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-forgot-password-js": () => import("/codebuild/output/src2449793091/src/bitbucket.org/teamgeek/submitt-web/src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src2449793091/src/bitbucket.org/teamgeek/submitt-web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/codebuild/output/src2449793091/src/bitbucket.org/teamgeek/submitt-web/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-reset-password-js": () => import("/codebuild/output/src2449793091/src/bitbucket.org/teamgeek/submitt-web/src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sign-in-js": () => import("/codebuild/output/src2449793091/src/bitbucket.org/teamgeek/submitt-web/src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("/codebuild/output/src2449793091/src/bitbucket.org/teamgeek/submitt-web/src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-terms-js": () => import("/codebuild/output/src2449793091/src/bitbucket.org/teamgeek/submitt-web/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

