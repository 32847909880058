/**
 * @flow
 */

import React from "react"
import { Elements, StripeProvider, injectStripe } from "react-stripe-elements"

const withStripe = (apiKey: string) => {
  return (WrappedComponent: typeof React.Component | Function) => {
    const ContainerComponent = (props: {}) => {
      const ComponentWithStripe = injectStripe(WrappedComponent)
      return (
        <StripeProvider apiKey={apiKey}>
          <Elements>
            <ComponentWithStripe {...props} />
          </Elements>
        </StripeProvider>
      )
    }
    return ContainerComponent
  }
}

export default withStripe
