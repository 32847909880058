/**
 * @flow
 */

import { Field, Form, Formik } from "formik"
import React from "react"
import * as yup from "yup"

import { Button, PasswordField, TextField } from "../elements"

const validationSchema = yup.object().shape({
  code: yup.string().required("Required"),
  password: yup.string().required("Required"),
  passwordConfirmation: yup
    .string()
    .required("Required")
    .oneOf([yup.ref("password"), null], "Passwords don't match"),
})

type Values = {
  code: string,
  password: string,
  passwordConfirmation: string,
}

type Actions = {
  setSubmitting: Function,
}

type Props = {
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
  initialValues?: Values,
}

const ResetPasswordForm = ({
  onSubmit,
  initialValues = {
    code: "",
    password: "",
    passwordConfirmation: "",
  },
}: Props) => (
  <Formik
    initialValues={initialValues}
    validateOnBlur={false}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <Form noValidate>
        <Field
          autoFocus
          component={TextField}
          id="code"
          label="Confirmation Code"
          name="code"
          required
        />
        <Field
          component={PasswordField}
          id="password"
          label="Password"
          name="password"
          type="password"
          required
        />
        <Field
          component={PasswordField}
          id="passwordConfirmation"
          label="Confirm Password"
          name="passwordConfirmation"
          type="password"
          required
        />
        <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
          Reset
        </Button>
      </Form>
    )}
  </Formik>
)

export default ResetPasswordForm
