/**
 * @flow
 */

import _ from "lodash"
import React from "react"

import CreditCard from "./CreditCard"
import type { Props as CreditCardProps } from "./CreditCard"
import CreditCardForm from "./CreditCardForm"
import styled from "styled-components"

type Props = {
  data: Array<CreditCardProps>,
}

const Container = styled.div`
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-size: 1.15rem;
    margin-bottom: 1rem;
  }
`

const CreditCardList = ({ data }: Props) => {
  // eslint-disable-next-line no-unused-vars
  const renderCreditCards = () => {
    return data.map((card: CreditCardProps) => (
      <CreditCard key={card.last4} {...card} />
    ))
  }

  const renderLatestCreditCard = () => {
    const latest = _.last(data)
    return latest ? <CreditCard {...latest} /> : null
  }

  return (
    <Container>
      <h2>Billing</h2>
      {renderLatestCreditCard()}
      <h3>Update card details</h3>
      <CreditCardForm submitTitle="Update Card" />
    </Container>
  )
}

export default CreditCardList
