import { combineReducers } from "redux"
import { createReducer } from "redux-starter-kit"

import { notify, setUser } from "./actions"

const notifications = createReducer(
  {
    notification: null,
  },
  {
    [notify]: (state, action) => {
      state.notification = action.payload
    },
  }
)

const auth = createReducer(
  {
    user: null,
  },
  {
    [setUser]: (state, action) => {
      const { payload = null } = action
      state.user = payload
    },
  }
)

const rootReducer = combineReducers({
  notifications,
  auth,
})

export default rootReducer
