/**
 * @flow
 */

import React from "react"
import styled from "styled-components"

const BRAND_ICONS = {
  "American Express": require("./img/american-express.png"),
  "Diners Club": require("./img/diners-club.png"),
  Discover: require("./img/discover.png"),
  JCB: require("./img/jcb.png"),
  MasterCard: require("./img/mastercard.png"),
  Visa: require("./img/visa.png"),
}
const DEFAULT_BRAND_ICON = require("./img/unknown.png")

export type Props = {
  last4: string,
  brand: string,
  expMonth: string | number,
  expYear: string | number,
}

const Container = styled.div`
  display: grid;
  grid-template: auto / auto 1fr;
  grid-gap: var(--gutter-s);
  margin-bottom: 2rem;
  div {
    p {
      font-size: 0.9rem;
      span {
        display: inline-block;
        min-width: 50px;
      }
    }
  }
`

const CreditCard = ({ last4, brand, expMonth, expYear }: Props) => {
  const image = BRAND_ICONS[brand] || DEFAULT_BRAND_ICON
  return (
    <Container>
      <img src={image} />
      <div>
        <p>
          <span className="font-semi-bold">nr: </span>
          **** **** **** {last4}
        </p>
        <p>
          <span className="font-semi-bold">exp: </span>
          {expMonth}/{expYear}
        </p>
      </div>
    </Container>
  )
}

export default CreditCard
