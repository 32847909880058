import React from "react"

import TextField from "./TextField"

const normalize = value => value.trim().toLowerCase()

const EmailField = props => {
  return <TextField type="email" normalize={normalize} {...props} />
}

export default EmailField
