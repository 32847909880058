/**
 * @flow
 */

import gql from "graphql-tag"
import React, { useState } from "react"
import { CardElement } from "react-stripe-elements"
import styled from "styled-components"

import { useMutation } from "@apollo/react-hooks"

import { Button } from "./elements"
import { withStripe } from "./utils"

type Props = {
  stripe: Object,
  submitTitle?: string,
  onSuccess?: () => void,
  onError?: (err: Error) => void,
  invertBackground: boolean,
}

const CardContainer = styled(CardElement)`
  padding: 0.5rem;
  background-color: ${props =>
    props.invertBackground ? `var(--white)` : `var(--alabaster)`};
  margin-bottom: 2rem;
  border-radius: 8px;
`

const CreditCardForm = ({
  stripe,
  submitTitle = "Save Card",
  onSuccess = () => {},
  onError = (err: Error) => {
    console.error(err)
  },
  invertBackground,
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [addTokenAsSource] = useMutation(ADD_TOKEN_AS_SOURCE_MUTATION)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const { token, error } = await stripe.createToken({
        type: "card",
      })
      if (error) {
        throw error
      }
      await addTokenAsSource({
        variables: {
          token: token.id,
        },
      })
      setLoading(false)
      onSuccess()
    } catch (err) {
      setLoading(false)
      onError(err)
    }
  }

  return (
    <div>
      <CardContainer
        invertBackground={invertBackground}
        hidePostalCode
        style={{
          base: { fontSize: "16px", color: "#424770" },
        }}
      />
      <Button
        type="submit"
        loading={loading}
        disabled={loading}
        onClick={handleSubmit}
      >
        {submitTitle}
      </Button>
    </div>
  )
}

export default withStripe(process.env.STRIPE_API_KEY)(CreditCardForm)

const ADD_TOKEN_AS_SOURCE_MUTATION = gql`
  mutation AddTokenAsSource($token: String!) {
    addPaymentSourceToPractice(sourceToken: $token) {
      ... on AddPaymentSourceToPracticeSuccess {
        practice {
          id
        }
      }
      ... on Error {
        message
      }
    }
  }
`
