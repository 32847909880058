/**
 * @flow
 */

import { navigate } from "gatsby"
import _ from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { useQuery } from "@apollo/react-hooks"

import { PRACTICE_QUERY } from "../queries"
import { notify } from "../state"
import CreditCardForm from "./CreditCardForm"
import PlanForm from "./PlanForm"
import PracticeForm from "./PracticeForm.js"
import FormContainer from "./elements/FormContainer"
import PageLoader from "./elements/PageLoader"
import StepIndicator from "./elements/StepIndicator"
import Layout from "./layout"
import { SEO } from "./utils"

const getPracticeName = data => _.get(data, "name")
const getCreditCards = data => _.get(data, "billing.cards", [])
const getActiveSubscriptionQuantity = data =>
  _.get(data, "billing.activeSubscriptionQuantity", 0)
const getPlanCost = data => _.get(data, "billing.planCost", 0)
const getCurrentUserCount = data => {
  return (
    _.get(data, "billing.currentUserCount", 0) +
    _.get(data, "billing.currentInviteCount", 0)
  )
}

const GetStartedScreen = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [practice, setPractice] = useState(null)
  const dispatch = useDispatch()

  const error = useCallback(
    message => {
      dispatch(
        notify({
          message: message,
          variant: "error",
        })
      )
    },
    [dispatch]
  )

  const success = useCallback(
    message => {
      dispatch(
        notify({
          message: message,
          variant: "success",
        })
      )
    },
    [dispatch]
  )

  const result = useQuery(PRACTICE_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  const fetching = result.networkStatus < 7

  useEffect(() => {
    if (!fetching && result.data) {
      const { practice } = result.data.me
      setPractice(practice)

      const name = getPracticeName(practice)
      const creditCards = getCreditCards(practice)
      const activeSubscriptionQuantity = getActiveSubscriptionQuantity(practice)

      if (_.isEmpty(name)) {
        setActiveStep(0)
      } else if (_.isEmpty(creditCards)) {
        setActiveStep(1)
      } else if (activeSubscriptionQuantity === 0) {
        setActiveStep(2)
      } else {
        navigate("/app")
      }
    }
  }, [fetching, result.data])

  const handleError = (err: Error) => {
    error(err.message)
  }

  // eslint-disable-next-line no-unused-vars
  const handlePracticeSave = data => {
    success("Practice saved successfully.")
    result.refetch()
  }

  const handleCreditCardSave = () => {
    success("Credit Card loaded successfully.")
    result.refetch()
  }

  const handlePlanChange = ({ quantity }) => {
    if (quantity > 1) {
      success("Subscription activated successfully.")
    } else {
      success("Plan updated successfully.")
    }
    result.refetch()
  }

  const renderStep = () => {
    if (activeStep === 0) {
      return (
        <div>
          <h2>Create a new practice</h2>
          <h4>Tell us about your practice?</h4>
          <PracticeForm
            submitButtonTitle="Next"
            onSave={handlePracticeSave}
            onError={handleError}
          />
        </div>
      )
    } else if (activeStep === 1) {
      return (
        <div>
          <h2>Load a Credit Card</h2>
          <h4>
            Enter the Credit Card details to use for the monthly subscription.
          </h4>
          <CreditCardForm
            onSuccess={handleCreditCardSave}
            onError={handleError}
            invertBackground
          />
        </div>
      )
    } else if (activeStep === 2) {
      const activeSubscriptionQuantity = getActiveSubscriptionQuantity(practice)
      const currentUserCount = getCurrentUserCount(practice)
      const planCost = getPlanCost(practice)
      return (
        <div>
          <h2>Activate your subscription.</h2>
          <h4>Start your plan with the desired number of users.</h4>
          <PlanForm
            activeSubscriptionQuantity={activeSubscriptionQuantity}
            currentUserCount={currentUserCount}
            planCost={planCost}
            onChange={handlePlanChange}
            onError={handleError}
            invertBackground
          />
        </div>
      )
    }
    return null
  }

  return (
    <Layout metaNavbar={true}>
      <SEO title="Get Started" />
      {fetching ? (
        <PageLoader />
      ) : (
        <FormContainer>
          <StepIndicator step={activeStep} />
          {renderStep()}
        </FormContainer>
      )}
    </Layout>
  )
}

export default GetStartedScreen
