/**
 * @flow
 */
import React from "react"
import type { Node } from "react"
import styled from "styled-components"

type Props = {
  children: Node,
}

const Container = styled.div`
  background-color: var(--alabaster);
  min-height: calc(100vh - 194px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px var(--gutter-s);
  @media (min-width: 768px) {
    padding: 130px var(--gutter-l);
    min-height: calc(100vh - 78px);
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    margin-bottom: 2rem;
  }
  h2 {
    font-family: "galano_grotesquelight";
    font-size: 1.35rem;
    margin-bottom: 0.5rem;
  }
  h4 {
    font-family: "galano_grotesquelight";
    margin-bottom: 2rem;
    color: var(--manatee);
  }
  form {
    width: calc(100vw - calc(2 * var(--gutter-s)));
    margin-bottom: 2rem;
  }
  .form-meta {
    text-align: center;
    font-size: 0.9rem;
    a {
      color: var(--havelock-blue);
      text-decoration: none;
    }
  }
  @media (min-width: 768px) {
    h1 {
      margin-bottom: 2.5rem;
    }
    form {
      width: calc(50vw - calc(2 * var(--gutter-l)));
    }
    .form-meta {
      p {
        text-align: center;
        margin-bottom: 0.5rem;
        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`

const FormContainer = ({ children }: Props) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  )
}

export default FormContainer
