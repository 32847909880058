import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

import { Notification } from "./elements"

const BaseLayout = ({ children }) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />
      <script src="https://js.stripe.com/v3/" />
    </Helmet>
    {children}
    <Notification />
  </div>
)

BaseLayout.propTypes = {
  children: PropTypes.node,
}

export default BaseLayout
