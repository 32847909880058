/**
 * @flow
 */
import React from "react"
import styled from "styled-components"

type Props = {
  step: number,
}

const Container = styled.div`
  min-width: 500px;
  align-items: center;
  display: grid;
  grid-template: auto / 50px 1fr 50px 1fr 50px;
  margin-bottom: 2rem;
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 50px;
    width: 50px;
    border: 3px solid var(--havelock-blue);
    border-radius: 50%;
    &.one {
      background-color: var(--havelock-blue);
    }
    &.two {
      background-color: ${props =>
        props.step > 0 ? `var(--havelock-blue)` : `transparent`};
      color: ${props => (props.step > 0 ? `white` : `var(--havelock-blue)`)};
    }
    &.three {
      background-color: ${props =>
        props.step > 1 ? `var(--havelock-blue)` : `transparent`};
      color: ${props => (props.step > 1 ? `white` : `var(--havelock-blue)`)};
    }
  }
  .bar {
    height: 3px;
    background-color: var(--havelock-blue);
  }
`

const StepIndicator = ({ step }: Props) => {
  return (
    <Container step={step}>
      <div className="step one font-black">1</div>
      <div className="bar"></div>
      <div className="step two font-black">2</div>
      <div className="bar"></div>
      <div className="step three font-black">3</div>
    </Container>
  )
}

export default StepIndicator
