import gql from "graphql-tag"

export const PRACTICE_QUERY = gql`
  query PracticeQuery {
    me {
      practice {
        id
        name
        practiceNumber
        physicalAddress
        telephoneNumber
        billing {
          activeSubscriptionQuantity
          currentUserCount
          currentInviteCount
          cards {
            brand
            country
            expMonth
            expYear
            last4
          }
          planCost
        }
      }
    }
  }
`

export const INVITES_QUERY = gql`
  query Invites($cursor: String) {
    me {
      practice {
        id
        name
        invites(first: 50, after: $cursor) {
          edges {
            node {
              id
              name
              email
              status
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`

export const SEND_INVITE_MUTATION = gql`
  mutation SendInvite($email: String!, $name: String!, $practiceId: ID!) {
    sendInvite(email: $email, name: $name, practiceId: $practiceId) {
      __typename
      ... on SendInviteSuccess {
        invite {
          id
          name
          email
          status
        }
      }
      ... on Error {
        message
      }
    }
  }
`

export const REMOVE_INVITES_MUTATION = gql`
  mutation RemoveInvites($ids: [ID!]!) {
    removeInvites(ids: $ids) {
      results {
        id
        error
      }
    }
  }
`
