import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import app_store from "../images/icons/apple-logo-icon.svg"
import play_store from "../images/icons/google-logo-icon.svg"

const Container = styled.footer`
  padding: var(--gutter-m) var(--gutter-s);
  background-color: var(--alabaster);
  color: var(--manatee);
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    &.app-store {
      a {
        img {
          height: 18px;
          width: auto;
        }
        &:last-child {
          margin-left: 1rem;
        }
      }
    }
    &.copyright {
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      p {
        margin-bottom: 0.5rem;
        margin-left: 1rem;
        font-size: 0.8rem;
      }
      .links {
        display: flex;
        span {
          margin: 0 1rem;
        }
        a {
          font-size: 0.8rem;
          transition: 250ms ease-out;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &.social {
      a {
        svg {
          width: 18px;
          height: 18px;
          fill: var(--manatee);
        }
        &:last-child {
          margin-left: 1rem;
        }
      }
    }
  }
  @media (min-width: 768px) {
    padding: var(--gutter-s) var(--gutter-l);
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    div {
      &.copyright {
        margin-bottom: 0;
        p {
          margin-left: 2rem;
        }
      }
    }
  }
`

const Footer = () => {
  return (
    <Container>
      <div className="app-store">
        <a
          href="https://apps.apple.com/za/app/submitt/id1457848422"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src={app_store}
            alt="Download the Submitt App from the App Store"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=me.submitt"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src={play_store}
            alt="Download the Submitt App from the Play Store"
          />
        </a>
      </div>
      <div className="copyright">
        <p>&copy; 2019 Submitt. All rights reserved.</p>
        <div className="links">
          <Link to="/privacy">Privacy </Link> <span>|</span>{" "}
          <Link to="/terms"> Terms</Link>
        </div>
      </div>
      <div className="social">
        <a
          href="https://www.facebook.com/submitt.me?fref=ts"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 24 24">
            <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
          </svg>
        </a>
        <a
          href="https://twitter.com/SubmittMe?lang=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 24 24">
            <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
          </svg>
        </a>
      </div>
    </Container>
  )
}

export default Footer
