/**
 * @flow
 */

import React from "react"
import styled from "styled-components"

type Props = {
  field: Object,
  form: Object,
  type?: string,
  label?: string,
  multiline?: boolean,
  normalize?: Function,
}

const Container = styled.div`
  margin-bottom: 1rem;
  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
  }
  p {
    input,
    textarea {
      border: none;
      border-radius: 8px;
      padding: 0.75rem;
      background-color: var(--white);
      width: 100%;
      font-size: 0.9rem;
      &:focus {
        outline: none;
        box-shadow: 2px 2px 10px -8px rgba(0, 0, 0, 0.5);
      }
    }
  }
  span {
    font-size: 0.9rem;
    padding: 0.5rem 0 0 0.5rem;
    display: inline-block;
    color: var(--pomegranate);
  }
`

const TextField = ({
  field,
  form,
  type = "text",
  label = "",
  multiline = false,
  normalize = v => v,
  ...props
}: Props) => {
  const error = form.errors[field.name] && form.touched[field.name]

  const handleBlur = () => {
    form.setFieldTouched(field.name, true)
  }

  const handleChange = event => {
    const value = normalize(event.target.value)
    form.setFieldValue(field.name, value)
  }

  return (
    <Container>
      <label>{label}</label>
      <p>
        {multiline ? (
          <textarea
            onBlur={handleBlur}
            onChange={handleChange}
            value={field.value}
            {...props}
          />
        ) : (
          <input
            type={type}
            onBlur={handleBlur}
            onChange={handleChange}
            value={field.value}
            {...props}
          />
        )}
      </p>
      <span> {error ? form.errors[field.name] : ""} </span>
    </Container>
  )
}

export default TextField
