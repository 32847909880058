/**
 * @flow
 */

import { navigate } from "gatsby"
import _ from "lodash"
import React, { useEffect } from "react"

import { useQuery } from "@apollo/react-hooks"

import { PRACTICE_QUERY } from "../queries"
import PageLoader from "./elements/PageLoader"

const checkProfileComplete = (practice: ?Object, routePath?: string) => {
  const name = _.get(practice, "name")
  const creditCards = _.get(practice, "billing.cards", [])
  const activeSubscriptionQuantity = _.get(
    practice,
    "billing.activeSubscriptionQuantity",
    0
  )
  if (
    _.isEmpty(name) ||
    (_.isEmpty(creditCards) && activeSubscriptionQuantity === 0)
  ) {
    if (routePath) {
      navigate(routePath)
    }
  }
}

const withPractice = (getStartedPath?: string) => {
  return (WrappedComponent: typeof React.Component | Function) => {
    const ContainerComponent = (props: {}) => {
      const result = useQuery(PRACTICE_QUERY, {
        notifyOnNetworkStatusChange: true,
      })
      const fetching = result.networkStatus < 7

      useEffect(() => {
        if (!fetching && result.data) {
          const { practice } = result.data.me
          checkProfileComplete(practice, getStartedPath)
        }
      }, [fetching, result.data])

      if (fetching) return <PageLoader />

      const { practice } = result.data.me
      if (!practice) return null
      return <WrappedComponent {...props} practice={practice} />
    }
    return ContainerComponent
  }
}

export default withPractice
