/**
 * @flow
 */

import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"

import Auth from "@aws-amplify/auth"
import { Logger } from "@aws-amplify/core"

import { notify } from "../../state"
import { toInternationalPhoneNumber } from "../../utils/phonenumber"
import FormContainer from "../elements/FormContainer"
import Layout from "../layout"
import SignUpForm from "./SignUpForm"

const SignUpPage = () => {
  const logger = useMemo(() => new Logger(SignUpPage.name), [])
  const dispatch = useDispatch()

  const handleSubmit = async (values, actions) => {
    const { firstName, lastName, email, mobileNumber, password } = values
    const phoneNumber = toInternationalPhoneNumber(mobileNumber)
    try {
      const result = await Auth.signUp({
        username: email,
        password,
        attributes: {
          given_name: firstName,
          family_name: lastName,
          email,
          phone_number: phoneNumber,
        },
      })
      logger.info("Sign up success.", result)

      if (result.userConfirmed) {
        const user = await Auth.signIn(email, password)
        logger.info("Sign in success.", user)
        navigate("/app", { replace: true })
      } else {
        navigate("/confirm-sign-up", {
          state: { email, password },
        })
      }
    } catch (err) {
      logger.error(err)
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Layout metaNavbar={true}>
      <FormContainer>
        <h1>Sign up</h1>
        <SignUpForm onSubmit={handleSubmit} />
        <div className="form-meta">
          <p>
            {"Already have an account?"}
            <a href="/sign-in"> Sign in</a>
          </p>
        </div>
      </FormContainer>
    </Layout>
  )
}

export default SignUpPage
