/**
 * @flow
 */

import { Field, Form, Formik } from "formik"
import React from "react"
import * as yup from "yup"

import { Button, EmailField } from "../elements"

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email")
    .required("Required"),
})

type Values = {
  email: string,
}

type Actions = {
  setSubmitting: Function,
}

type Props = {
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
  initialValues?: Values,
}

const ForgotPasswordForm = ({
  onSubmit,
  initialValues = {
    email: "",
  },
}: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Field
            autoFocus
            component={EmailField}
            id="email"
            label="Email Address"
            name="email"
            required
          />
          <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
            Send
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default ForgotPasswordForm
