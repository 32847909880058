/**
 * @flow
 */

import React from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import Auth from "@aws-amplify/auth"

import { setUser } from "../state"
import CreditCardList from "./CreditCardList"
import InviteList from "./InviteList"
import SubscriptionView from "./SubscriptionView"
import { Button } from "./elements"
import Layout from "./layout"
import withPracice from "./withPractice"

import app_store from "../images/icons/apple-logo.svg"
import play_store from "../images/icons/google-logo.svg"

const DashboardContainer = styled.section`
  padding: var(--gutter-l) var(--gutter-s) var(--gutter-s);
  background-color: var(--alabaster);
  display: grid;
  grid-template: auto auto auto auto / 1fr;
  grid-gap: var(--gutter-m);
  @media (min-width: 768px) {
    grid-template: auto auto auto auto / 1fr 1fr;
    padding: calc(1.5 * var(--gutter-l)) var(--gutter-l) var(--gutter-l);
  }
`

const TopBar = styled.div`
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  margin-bottom: 2rem;
  p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  div {
    p {
      margin-bottom: 1rem;
      color: #8f90a3;
    }
    img {
      width: 100px;
      height: auto;
      &:first-child {
        margin-right: 0rem;
        margin-left: 2rem;
      }
    }
  }
  @media (min-width: 768px) {
    grid-column: 1 / 3;
    flex-direction: row;
    justify-content: space-between;
    div {
      p {
        text-align: right;
      }
      img {
        width: 100px;
        &:first-child {
          margin-right: 0rem;
          margin-left: 2rem;
        }
      }
    }
  }
`

const BottomBar = styled.div`
  grid-row: 4 / 5;
  grid-column: 1 / 2;
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    grid-column: 1 / 3;
  }
`

const LogoutButton = styled(Button)`
  padding: 0 3rem;
  background: rgb(255, 179, 161, 1);
  background: linear-gradient(
    90deg,
    rgba(255, 179, 161, 1) 35%,
    rgba(255, 141, 157, 1) 100%
  );
`

const Tile = styled.div`
  background-color: var(--white);
  border-radius: 8px;
  padding: var(--gutter-s);
  box-shadow: 2px 2px 10px -8px rgba(0, 0, 0, 0.5);
  @media (min-width: 768px) {
    grid-column: span ${props => props.span};
  }
`

type Props = {
  practice: Object,
}

const HomeScreen = ({ practice }: Props) => {
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  const handleSignOut = async () => {
    await Auth.signOut()
    dispatch(setUser())
  }

  return (
    <Layout metaNavbar={true} isDashboard={true}>
      <DashboardContainer>
        <TopBar>
          <p>
            <strong className="font-semi-bold">
              Welcome back {user.firstName}
            </strong>
            , here is your practice overview
          </p>
          <div>
            <p>Download your Submitt App below,</p>
            <div>
              <a
                href="https://apps.apple.com/za/app/submitt/id1457848422"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={app_store} alt="Submitt App Store download" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=me.submitt"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={play_store} alt="Submitt Play Store download" />
              </a>
            </div>
          </div>
        </TopBar>
        <Tile span={2}>
          <InviteList practice={practice} />
        </Tile>
        <Tile span={1}>
          <SubscriptionView practice={practice} />
        </Tile>
        <Tile span={1}>
          <CreditCardList data={practice.billing.cards} />
        </Tile>
        <BottomBar>
          <div className="button-container">
            <LogoutButton onClick={handleSignOut}>Sign out</LogoutButton>
          </div>
        </BottomBar>
      </DashboardContainer>
    </Layout>
  )
}

export default withPracice("/app/get-started")(HomeScreen)
