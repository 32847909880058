import { navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"

const PrivateRoute = ({ component: Component, user, ...props }) => {
  useEffect(() => {
    ;(async () => {
      if (!user) {
        navigate("/sign-in", { replace: true })
      }
    })()
  }, [user])

  if (user) {
    return <Component user={user} {...props} />
  }

  return null
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  user: PropTypes.object,
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(PrivateRoute)
