/**
 * @flow
 */

import { createAction } from "redux-starter-kit"

type NotifyPayload = {
  message: string,
  variant: "success" | "warning" | "error" | "info",
}

export const notify: (payload: NotifyPayload) => Object = createAction(`NOTIFY`)
export const setUser = createAction(`SET_USER`)
