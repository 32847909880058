import React from "react"

import { BaseLayout } from "./components"

// eslint-disable-next-line react/prop-types
const wrapPageElement = ({ element, props }) => {
  return <BaseLayout {...props}>{element}</BaseLayout>
}

export default wrapPageElement
