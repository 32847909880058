import React from "react"
import PropTypes from "prop-types"

import Navbar from "./Navbar.js"
import Footer from "./Footer.js"

const Layout = ({ children, metaNavbar, isDashboard }) => {
  return (
    <>
      <Navbar metaNavbar={metaNavbar} isDashboard={isDashboard} />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  metaNavbar: PropTypes.bool,
  isDashboard: PropTypes.bool,
}

export default Layout
