/**
 * @flow
 */

import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"

import Auth from "@aws-amplify/auth"
import { Logger } from "@aws-amplify/core"

import { notify } from "../../state"
import FormContainer from "../elements/FormContainer"
import Layout from "../layout"
import ForgotPasswordForm from "./ForgotPasswordForm"

const ForgotPasswordPage = () => {
  const logger = useMemo(() => new Logger(ForgotPasswordPage.name), [])
  const dispatch = useDispatch()

  const handleSubmit = async (values, actions) => {
    const { email } = values
    try {
      await Auth.forgotPassword(email)
      navigate("/reset-password", {
        state: { email },
      })
    } catch (err) {
      logger.error(err)
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Layout metaNavbar={true}>
      <FormContainer>
        <h1>Forgot password</h1>
        <ForgotPasswordForm onSubmit={handleSubmit} />
        <div className="form-meta">
          <p>
            {"Remember password?"}
            <a href="/sign-in"> Sign in</a>
          </p>
        </div>
      </FormContainer>
    </Layout>
  )
}

export default ForgotPasswordPage
