/**
 * @flow
 */

import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import Auth from "@aws-amplify/auth"
import { Logger } from "@aws-amplify/core"
import { Router } from "@reach/router"

import { setUser } from "../state"
import { GetStartedScreen, HomeScreen, NotFoundPage, PrivateRoute } from "."

const App = () => {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  const logger = useMemo(() => new Logger(App.name), [])

  const fetchAuthenticatedUser = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      logger.debug(user)

      const {
        given_name: firstName,
        family_name: lastName,
        email,
        phone_number: mobileNumber,
      } = user.attributes

      dispatch(
        setUser({
          firstName,
          lastName,
          email,
          mobileNumber,
        })
      )
    } catch (err) {
      logger.error(err)
      dispatch(setUser())
    } finally {
      setLoading(false)
    }
  }, [dispatch, logger])

  useEffect(() => {
    fetchAuthenticatedUser()
  }, [fetchAuthenticatedUser])

  if (loading) return null

  return (
    <Router>
      <PrivateRoute path="app" component={HomeScreen} />
      <PrivateRoute path="app/get-started" component={GetStartedScreen} />
      <NotFoundPage default />
    </Router>
  )
}

export default App
