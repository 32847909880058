/**
 * @flow
 */

import { Field, Form, Formik } from "formik"
import React from "react"
import * as yup from "yup"
import styled from "styled-components"

import { Button, EmailField, TextField } from "./elements"

const InlineFormContainer = styled.div`
  h3 {
    font-size: 1.15rem;
    margin-bottom: 1rem;
  }
  form {
    display: grid;
    grid-template: auto auto auto / 1 fr;
    .field-container {
      input {
        background-color: var(--alabaster);
      }
    }
    .button-container {
      display: flex;
      align-items: center;
    }
  }
  @media (min-width: 768px) {
    form {
      grid-gap: calc(1 * var(--gutter-m));
      grid-template: auto / 1fr 1fr 250px;
    }
  }
`

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup
    .string()
    .email()
    .required(),
})

export type Values = {
  id?: number | string,
  name: string,
  email: string,
}

type Actions = {
  setSubmitting: Function,
  resetForm: Function,
}

type Props = {
  initialValues?: Values,
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
}

const InviteForm = ({
  initialValues = {
    name: "",
    email: "",
  },
  onSubmit,
}: Props) => {
  const renderForm = ({
    dirty,
    isSubmitting,
  }: {
    dirty: boolean,
    isSubmitting: boolean,
  }) => (
    <Form noValidate>
      <div className="field-container">
        <Field
          autoFocus
          component={TextField}
          id="name"
          label="Name"
          name="name"
          required
        />
      </div>
      <div className="field-container">
        <Field
          autoFocus
          component={EmailField}
          id="email"
          label="Email Address"
          name="email"
          required
        />
      </div>
      <div className="button-container">
        <Button
          type="submit"
          loading={isSubmitting}
          disabled={!dirty || isSubmitting}
        >
          Send Invite
        </Button>
      </div>
    </Form>
  )

  return (
    <InlineFormContainer>
      <h3>Add user</h3>
      <Formik
        validateOnBlur={false}
        validationSchema={validationSchema}
        validateOnChange={false}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {renderForm}
      </Formik>
    </InlineFormContainer>
  )
}

export default InviteForm
