/**
 * @flow
 */

import { Field, Form, Formik } from "formik"
import gql from "graphql-tag"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import * as yup from "yup"

import { useMutation } from "@apollo/react-hooks"

import { Button, TextField } from "./elements"

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  practiceNumber: yup.string().required(),
  telephoneNumber: yup.string().required(),
  physicalAddress: yup.string().required(),
})

export type Values = {
  id?: number | string,
  name: string,
  practiceNumber: string,
  telephoneNumber: string,
  physicalAddress: string,
}

type Props = {
  data?: Values,
  submitButtonTitle?: ?string,
  onSave?: (data: Values) => void,
  onError?: (err: Error) => void,
}

const PracticeForm = ({
  data = {
    name: "",
    practiceNumber: "",
    telephoneNumber: "",
    physicalAddress: "",
  },
  submitButtonTitle,
  onSave = (data: Values) => {
    // eslint-disable-next-line no-console
    console.log(data)
  },
  onError = (err: Error) => {
    console.error(err)
  },
}: Props) => {
  const [savePractice, result] = useMutation(SAVE_PRACTICE_MUTATION)

  let submitTitle = submitButtonTitle
  if (!submitTitle) {
    submitTitle = data.id ? "Save Changes" : "Create Practice"
  }

  useEffect(() => {
    if (result.error) {
      onError(result.error)
    }
  }, [onError, result.error])

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { name, practiceNumber, telephoneNumber, physicalAddress } = values

    const variables = {
      id: data.id,
      name,
      practiceNumber,
      telephoneNumber,
      physicalAddress,
    }
    try {
      await savePractice({
        variables,
      })

      setSubmitting(false)
      resetForm({
        ...data,
        ...values,
      })
      onSave(variables)
    } catch (err) {
      console.error(err)
      setSubmitting(false)
    }
  }

  const renderForm = ({
    dirty,
    isSubmitting,
  }: {
    dirty: boolean,
    isSubmitting: boolean,
  }) => (
    <Form noValidate>
      <Field
        autoFocus
        component={TextField}
        id="name"
        label="Name"
        name="name"
        required
      />
      <Field
        autoFocus
        component={TextField}
        id="practiceNumber"
        label="Practice Number"
        name="practiceNumber"
        required
      />
      <Field
        autoFocus
        component={TextField}
        id="telephoneNumber"
        label="Telephone Number"
        name="telephoneNumber"
        required
      />
      <Field
        autoFocus
        component={TextField}
        id="physicalAddress"
        label="Physical Address"
        name="physicalAddress"
        multiline={true}
        required
      />
      <Button
        type="submit"
        loading={isSubmitting}
        disabled={!dirty || isSubmitting}
      >
        {submitTitle}
      </Button>
    </Form>
  )

  return (
    <Formik
      validateOnBlur={false}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleSubmit}
      initialValues={data}
    >
      {renderForm}
    </Formik>
  )
}

PracticeForm.propTypes = {
  data: PropTypes.object,
  submitButtonTitle: PropTypes.string,
}

export default PracticeForm

const SAVE_PRACTICE_MUTATION = gql`
  mutation savePractice(
    $name: String!
    $practiceNumber: String!
    $telephoneNumber: String!
    $physicalAddress: String!
    $id: ID
  ) {
    savePractice(
      name: $name
      practiceNumber: $practiceNumber
      telephoneNumber: $telephoneNumber
      physicalAddress: $physicalAddress
      id: $id
    ) {
      ... on SavePracticeSuccess {
        practice {
          id
        }
      }
      ... on Error {
        message
      }
    }
  }
`
