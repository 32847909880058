/**
 * @flow
 */

import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { isMobile } from "react-device-detect"
import styled from "styled-components"

import { useMutation, useQuery } from "@apollo/react-hooks"

import {
  INVITES_QUERY,
  PRACTICE_QUERY,
  REMOVE_INVITES_MUTATION,
  SEND_INVITE_MUTATION,
} from "../queries"
import { notify } from "../state"
import InviteForm from "./InviteForm"

type Props = {
  practice: Object,
}

const Container = styled.div`
  h2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`

const ListContainer = styled.div`
  /* display: flex; */
  /* flex/ */
`

const ListRow = styled.div`
  display: grid;
  grid-template: auto / 2fr 1fr 50px;
  padding: 1rem;
  &.head {
  }
  &:nth-child(2n) {
    background-color: var(--alabaster);
  }
  svg {
    width: 16px;
    height: 16px;
    transition: all 250ms ease-out;
    cursor: pointer;
    &:hover {
      fill: var(--pomegranate);
    }
  }
  @media (min-width: 768px) {
    grid-template: auto / 1fr 1fr 1fr 100px;
  }
`

const InviteList = ({ practice }: Props) => {
  const [removing, setRemoving] = useState({})
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  const invitesResult = useQuery(INVITES_QUERY, {
    notifyOnNetworkStatusChange: true,
  })

  const [sendInvite] = useMutation(SEND_INVITE_MUTATION, {
    refetchQueries: [
      {
        query: PRACTICE_QUERY,
      },
    ],
  })

  const [removeInvites] = useMutation(REMOVE_INVITES_MUTATION, {
    refetchQueries: [
      {
        query: PRACTICE_QUERY,
      },
    ],
  })

  useEffect(() => {
    if (invitesResult.error) {
      dispatch(
        notify({
          message: invitesResult.error.message,
          variant: "error",
        })
      )
    }
  }, [dispatch, invitesResult.error])

  const renderRows = () => {
    const edges = _.get(invitesResult, "data.me.practice.invites.edges", [])
    return _.map(edges, ({ node }) => {
      const { id, name, email, status } = node

      if (status === "REMOVED") return null

      const handleRemoveClick = async () => {
        setRemoving({
          ...removing,
          [id]: true,
        })
        const variables = {
          ids: [id],
        }
        try {
          await removeInvites({ variables })
          invitesResult.refetch()
        } catch (err) {
          console.error(err)
          window.alert(err.message)
        } finally {
          setRemoving({
            ...removing,
            [id]: false,
          })
        }
      }

      return (
        <ListRow key={id}>
          {!isMobile && <div>{name}</div>}
          {isMobile ? (
            <div>{email.substring(0, 10)}...</div>
          ) : (
            <div>{email}</div>
          )}
          <div>{status}</div>
          <div>
            <svg
              onClick={handleRemoveClick}
              // loading={removing[id]}
              // disabled={removing[id]}
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
              viewBox="0 0 24 24"
            >
              <path d="M19 24h-14c-1.104 0-2-.896-2-2v-17h-1v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2h-1v17c0 1.104-.896 2-2 2zm0-19h-14v16.5c0 .276.224.5.5.5h13c.276 0 .5-.224.5-.5v-16.5zm-7 7.586l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm2-10.586h-4v1h4v-1z" />
            </svg>
          </div>
        </ListRow>
      )
    })
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { name, email } = values

    const variables = {
      name,
      email,
      practiceId: practice.id,
    }
    try {
      const result = await sendInvite({
        variables,
      })

      if (result.data.sendInvite.__typename === "SendInviteSuccess") {
        invitesResult.refetch()
      } else {
        window.alert(result.data.sendInvite.message)
      }

      setSubmitting(false)
      resetForm({
        name: "",
        email: "",
      })
    } catch (err) {
      console.error(err)
      setSubmitting(false)
      window.alert(err.message)
    }
  }

  return (
    <Container>
      <h2 className="font-semi-bold">Users</h2>
      <ListContainer>
        <ListRow className="head">
          {!isMobile && <div className="font-semi-bold">Name</div>}
          <div className="font-semi-bold">Email</div>
          <div className="font-semi-bold">Status</div>
        </ListRow>
        <ListRow className="owner">
          {!isMobile && (
            <div>
              {user.firstName} {user.lastName}
            </div>
          )}
          {isMobile ? (
            <div>{user.email.substring(0, 10)}...</div>
          ) : (
            <div>{user.email}</div>
          )}
          <div className="font-semi-bold">OWNER</div>
        </ListRow>
        {renderRows()}
      </ListContainer>
      <br />
      <InviteForm onSubmit={handleSubmit} />
      <br />
    </Container>
  )
}

export default InviteList
