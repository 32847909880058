/**
 * @flow
 */

import { navigate } from "gatsby"
import React, { useMemo } from "react"
import { useDispatch } from "react-redux"

import Auth from "@aws-amplify/auth"
import { Logger } from "@aws-amplify/core"

import { notify } from "../../state"
import FormContainer from "../elements/FormContainer"
import Layout from "../layout"
import ResetPasswordForm from "./ResetPasswordForm"

type Props = {
  location: Object,
}

const ResetPasswordPage = ({ location }: Props) => {
  const logger = useMemo(() => new Logger(ResetPasswordPage.name), [])
  const dispatch = useDispatch()

  const handleSubmit = async (values, actions) => {
    const { code, password } = values
    const { email } = location.state
    try {
      await Auth.forgotPasswordSubmit(email, code, password)
      dispatch(
        notify({
          message: "Your password was reset successfully! You may now sign in.",
          variant: "success",
        })
      )
      navigate("/sign-in", { replace: true })
    } catch (err) {
      logger.error(err)
      dispatch(
        notify({
          message: err.message,
          variant: "error",
        })
      )
    } finally {
      actions.setSubmitting(false)
    }
  }

  return (
    <Layout metaNavbar={true}>
      <FormContainer>
        <h1>Reset password</h1>
        <ResetPasswordForm onSubmit={handleSubmit} />
        <div className="form-meta">
          <p>
            {"Didn't get the confirmation code?"}
            <a href="/forgot-password"> Try again</a>
          </p>
        </div>
      </FormContainer>
    </Layout>
  )
}

export default ResetPasswordPage
