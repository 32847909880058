/**
 * @flow
 */

import { navigate } from "gatsby"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch } from "react-redux"

import Auth from "@aws-amplify/auth"
import { Logger } from "@aws-amplify/core"

import { notify } from "../../state"
import FormContainer from "../elements/FormContainer"
import Layout from "../layout"
import SignInForm from "./SignInForm"

const SignInPage = () => {
  const [loading, setLoading] = useState(true)
  const logger = useMemo(() => new Logger(SignInPage.name), [])
  const dispatch = useDispatch()

  const checkAuthenticatedUser = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      // Got authenticated user
      navigate("/app", { replace: true })
      dispatch(
        notify({
          message: `Welcome back ${user.attributes.given_name}.`,
          variant: "info",
        })
      )
    } catch (err) {
      // No authenticated user
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    checkAuthenticatedUser()
  }, [checkAuthenticatedUser])

  const handleSubmit = async (values, actions) => {
    const { email, password } = values
    try {
      const user = await Auth.signIn(email, password)
      logger.info(user)
      dispatch(
        notify({
          message: "You signed in successfully.",
          variant: "success",
        })
      )
      setTimeout(() => {
        navigate("/app", { replace: true })
      }, 500)
    } catch (err) {
      logger.error(err)
      if (err.code === "UserNotConfirmedException") {
        await Auth.resendSignUp(email)
        navigate("/confirm-sign-up", {
          state: { email, password },
        })
      } else {
        dispatch(
          notify({
            message: err.message,
            variant: "error",
          })
        )
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  if (loading) return null

  return (
    <Layout metaNavbar={true}>
      <FormContainer>
        <h1>Sign in</h1>
        <SignInForm onSubmit={handleSubmit} />
        <div className="form-meta">
          <p>
            <a href="/forgot-password">Forgot password?</a>
          </p>
          <p>
            {"Don't have an account?"}
            <a href="/sign-up"> Sign up</a>
          </p>
        </div>
      </FormContainer>
    </Layout>
  )
}

export default SignInPage
