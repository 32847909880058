import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

const Container = styled.div`
  background-color: ${props =>
    props.variant === "error" ? "#e74c3c" : "#27ae60"};
  color: white;
  min-width: 300px;
  padding: 1.75rem 1rem;
  position: fixed;
  bottom 40px;
  left: 40px;
  border-radius: 8px;
  font-size: 0.9rem;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    svg {
      cursor: pointer;
      fill: #fff;
      width: 16px;
      height: 16px;
    }
  }
`

const Notification = ({ notification }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(false)

    setTimeout(() => {
      setOpen(true)
    }, 300)

    setTimeout(() => {
      setOpen(false)
    }, 5000)
  }, [notification])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {notification && open ? (
        <Container variant={notification.variant}>
          {notification.message}
          <div onClick={handleClose} className="close">
            <svg viewBox="0 0 24 24">
              <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 16.538l-4.592-4.548 4.546-4.587-1.416-1.403-4.545 4.589-4.588-4.543-1.405 1.405 4.593 4.552-4.547 4.592 1.405 1.405 4.555-4.596 4.591 4.55 1.403-1.416z" />
            </svg>
          </div>
        </Container>
      ) : null}
    </>
  )
}

Notification.propTypes = {
  notification: PropTypes.object,
}

export default connect(state => ({
  notification: state.notifications.notification,
}))(Notification)
