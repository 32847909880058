/**
 * @flow
 */

import { Field, Form, Formik } from "formik"
import React from "react"
import * as yup from "yup"

import { Button, EmailField, PasswordField } from "../elements"

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Required"),
  password: yup.string().required("Required"),
})

type Values = {
  email: string,
  password: string,
}

type Actions = {
  setSubmitting: Function,
}

type Props = {
  onSubmit: (values: Values, actions: Actions) => void | Promise<mixed>,
  initialValues?: Values,
}

const SignInForm = ({
  onSubmit,
  initialValues = {
    email: "",
    password: "",
  },
}: Props) => (
  <Formik
    initialValues={initialValues}
    validateOnBlur={false}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <Form noValidate>
        <Field
          autoFocus
          component={EmailField}
          id="email"
          label="Email Address"
          name="email"
          required
        />
        <Field
          component={PasswordField}
          id="password"
          label="Password"
          name="password"
          required
        />
        <Button type="submit" loading={isSubmitting} disabled={isSubmitting}>
          Sign In
        </Button>
      </Form>
    )}
  </Formik>
)

export default SignInForm
